/* General Styles */
.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: 0;
  /* Remove horizontal centering */
  padding: 0;
}

/* Hero Section */
.Hero-background {
  background-size: cover;
  background-position: center;
  padding: 100px 5%;
  /* Add padding to avoid edge contact */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Hero-content {
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background for readability */
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  /* Constrain width on larger screens */
}

.App-logo {
  width: 380px;
  max-width: 100%;
  margin-bottom: 20px;
}

.App-title {
  font-size: 2.5em;
  margin: 0;
}

.App-tagline {
  font-size: 1.5em;
  margin: 10px 0 20px 0;
}

.App-description {
  font-size: 1.2em;
  max-width: 600px;
  margin: 0 auto;
}

/* Features Section */
/* Features Section */
.Features {
  padding: 50px 5%;
  background-color: #f9f9f9;
}

.Features h3 {
  font-size: 2em;
  margin-bottom: 30px;
  text-align: left;
  /* Align the heading left */
}

.Feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Feature-list li {
  display: flex;
  font-size: 1.1em ;
  align-items: flex-start;
  margin-bottom: 20px;
  text-align: left;
  /* Align text to the left */
}

.Feature-icon {
  font-size: 2em;
  color: #007BFF;
  margin-right: 15px;
}
/* Launch Announcement */
.Launch-info {
  background-color: #e0e0e0;
  padding: 40px 5%;
}

.Launch-info h3 {
  font-size: 2em;
  margin-bottom: 20px;
}

/* Contact Section */
.Contact {
  padding: 50px 5%;
}

.Contact h3 {
  font-size: 2em;
  margin-bottom: 20px;
}

.Contact-form {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
  /* Ensure it wraps on small screens */
}

.Email-input {
  padding: 15px;
  font-size: 1em;
  width: 300px;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

.Subscribe-button {
  padding: 15px 30px;
  font-size: 1em;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.Subscribe-button:hover {
  background-color: #0056b3;
}

/* Footer */
.App-footer {
  background-color: #333;
  color: white;
  padding: 20px;
}

.App-footer p {
  margin: 0;
}

/* Contact Information Section */
.Contact-info {
  padding: 20px 5%;
  background-color: #f0f2f5;
  text-align: center;
  margin-top: 30px;
}

.Contact-info h3 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.Contact-info p {
  font-size: 1.1em;
}

.Contact-info a {
  color: #007bff;
  text-decoration: none;
}

.Contact-info a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .Hero-content {
    padding: 20px;
    width: 90%;
  }

  .App-logo {
    width: 150px;
  }

  .Feature-list li {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .Feature-icon {
    margin-bottom: 10px;
  }

  .Contact-form {
    flex-direction: column;
  }

  .Email-input,
  .Subscribe-button {
    width: 100%;
    margin: 5px 0;
    border-radius: 5px;
  }

  .App-description,
  .App-title {
    font-size: 1.2em;
  }

  .App-tagline {
    font-size: 1.1em;
  }
}